@media print {
	@page {
		size: auto;
		margin: 5mm 0 5mm 0;
	}

	html, body {
		direction: rtl;
	}

	table td, table th {
		font-size: 10px !important;
	}
}

.nowrap {
	white-space: nowrap;
}
