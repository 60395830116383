._loading_overlay_overlay{
	background: rgba(255, 255, 255, 0.6);

	circle{
		stroke: #0c3c7a !important;
		stroke-width: 3px;
		opacity: 0.8;
	}
}

.MuiPopover-root, .MuiAutocomplete-popper, .MuiDialog-root {
	z-index: 99999!important;
}

.swal-modal, .swal-button{
	font-family: Cairo, serif;
}

.swal-title {
	color: rgb(88, 88, 88);
	margin-bottom: 0!important;
}
