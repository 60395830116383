/* width */
::-webkit-scrollbar {
	width: 1px !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #FAFAFB !important;
	border-radius: 3px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #c2c2c2 !important;
}

.swal-text{
	text-align: center;
}

div[role="presentation"], .MuiDrawer-modal, .MuiDrawer-docked > .MuiPaper-root{
	z-index: 9999!important;
}

.rdw-dropdown-carettoclose, .rdw-dropdown-carettoopen {
	left: 10%;
	right: auto;
}

@media print {
	body {
		background-color: white!important;
	}
}

ul.MuiList-root > li.MuiListSubheader-root:only-child {
	display: none;
}

ul, ol {
	margin: 0 15px
}
