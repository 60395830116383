.MuiToolbar-root{
	font-family: Cairo, sans-serif;
	border-bottom: 1px solid #eeeeee;
	font-weight: 600;
}

.MuiTableRow-root .MuiFormControl-root{
	border: 1px solid #ddd;
	padding-left: 10px;
	border-radius: 6px;
	padding-right: 10px;

	.MuiInputBase-root:before, .MuiInputBase-root:after{
		display: none;
	}

	svg{
		fill: #caccce;
		font-size: 20px;
	}
}

tr.MuiTableRow-root[level="1"]{
	background: #f3f5f7;
}
